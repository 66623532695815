<template>
  <v-container> 
    <week-graph/>
  </v-container>
</template>

<script>
import WeekGraph from '../components/WeekGraph.vue'
export default {
  components: { WeekGraph },
  
}
</script>