<template>
  <line-chart v-if="loaded" :chartdata="chartdata" :options="options" />
</template>

<script>
import LineChart from "./LineChart.vue";
import DataService from "../services/DataService";
import moment from "moment-timezone";

export default {
  name: "WeekGraph",
  components: { LineChart },
  data: () => ({
    loaded: false,
    chartdata: null,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            afterTickToLabelConversion: function (data) {
              var xLabels = data.ticks;
              xLabels.forEach(function (labels, i) {
                if (i % 3 !== 0) {
                  xLabels[i] = "";
                }
              });
            },
          },
        ],
      },
    },
  }),
  async mounted() {
    this.loaded = false;
    try {
      let response = await DataService.getWeekHistory("Sam");

      let readableTimes = response.timestamp.map((epoch) =>
        moment.unix(epoch).format("M/D/Y, h:mm a")
      );

      // let correctedEquity = response.equity.map((equity) => {
      //   return equity = equity + 100000
      // })

      let tempData = {};
      tempData.labels = readableTimes;
      tempData.datasets = [
        {
          label: "Equity",
          backgroundColor: "#f87979",
          data: response.equity,
        },
      ];
      this.chartdata = tempData;
      this.loaded = true;
    } catch (e) {
      console.log(e);
    }
  },
};
</script>